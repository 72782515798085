import React, { useEffect } from 'react';
import LayoutStepStart from "../../../hoc/LayoutStepStart/LayoutStepStart";
//import { resetLocalStorage } from "../../../store/actions/investorProfileActions";
import { connect } from "react-redux";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const Step3 = ( props ) => {

    useEffect(()=>{
        //props.resetLocalStorage('savingAndInvestments')
    })
    return (

        <LayoutStepStart
            title ='YOUR SAVINGS & INVESTMENTS'
            step={3}
            next={retirementCalcLinks.savingsOrInvestmentsSelector}
        >
            <p>Knowing what you already have to work with will help you determine how you can reach your financial goals.</p>
        </LayoutStepStart>
    )
};

function mapDispatchToProps(dispatch) {
    return {
        //resetLocalStorage: (step)=>dispatch(resetLocalStorage(step))
    }
}

export default connect(null, mapDispatchToProps)(Step3);